"use client";

import React from "react";
import { useState } from "react";
import { api } from "~/trpc/react";
import { useSession } from "next-auth/react";
import { DialogTitle } from "~/components/ui/dialog";
import { ToggleGroup, ToggleGroupItem } from "~/components/ui/toggle-group";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { Loader2, Play } from "lucide-react";
import { Button } from "~/components/ui/button";
import { useRouter } from "next/navigation";

interface ChatCreationModalProps {
  setIsOnboardingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogContent: React.Dispatch<React.SetStateAction<string>>;
}

const ChatCreationModal: React.FC<ChatCreationModalProps> = ({
  setIsOnboardingModalOpen,
  setDialogContent,
}) => {
  const utils = api.useUtils();
  const router = useRouter();
  const { data: session } = useSession();

  const createChatMutation = api.chat.createChat.useMutation({});
  const getDefaultScenarios = api.chat.getDefaultScenarios.useQuery();
  const getDefaultTutors = api.tutor.getDefaultTutors.useQuery();
  const updateSettingsMutation = api.settings.update.useMutation();

  const [scenarioValue, setScenarioValue] = useState("");
  const [tutorValue, setTutorValue] = useState("");

  const handleSubmit = () => {
    try {
      if (session?.user.id) {
        createChatMutation.mutate(
          {
            scenarioId: scenarioValue,
          },
          {
            onSuccess: (response) => {
              setDialogContent("chooseLanguage");
              setIsOnboardingModalOpen(false);

              void router.push(`/chats/${response.id}`);
              void utils.invalidate();
            },
            onError: () => {
              console.error("Error creating chat");
            },
          },
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        <DialogTitle className=" text-center text-3xl">
          Create your first chat
        </DialogTitle>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex min-h-[360px] min-w-[240px] flex-col items-center gap-4 rounded-lg border border-gray-100 bg-white p-8 shadow-sm">
            <p className="text-xl">Scenario</p>
            <ToggleGroup
              variant="outline"
              type="single"
              className="grid grid-cols-2 gap-2"
              value={scenarioValue}
              onValueChange={(scenarioValue) => {
                if (scenarioValue) setScenarioValue(scenarioValue);
              }}
            >
              {getDefaultScenarios.data
                ?.filter((scenario) => scenario.icon)
                .map((scenario) => {
                  return (
                    <ToggleGroupItem
                      key={scenario.id}
                      value={scenario.id}
                      className="flex min-h-[100px] flex-col gap-1"
                      // aria-label="Toggle bold"
                    >
                      <p className="text-3xl"> {scenario.icon} </p>
                      <p className="text-xs"> {scenario.title}</p>
                    </ToggleGroupItem>
                  );
                })}
            </ToggleGroup>
          </div>

          <div className="flex min-h-[360px] min-w-[240px] flex-col items-center gap-4 rounded-lg border border-gray-100 bg-white p-8 shadow-sm">
            <p className="text-xl">Tutor</p>
            <ToggleGroup
              variant="outline"
              type="single"
              className="flex min-h-[100px] flex-col gap-1"
              value={tutorValue}
              onValueChange={(tutorValue) => {
                if (tutorValue) {
                  setTutorValue(tutorValue);
                  updateSettingsMutation.mutate({
                    defaultTutorId: tutorValue,
                  });
                }
              }}
            >
              {getDefaultTutors.data?.map((tutor) => {
                return (
                  <div className="flex flex-col" key={tutor.id}>
                    <ToggleGroupItem
                      value={tutor.id}
                      className="flex min-h-[60px] min-w-[250px] justify-start gap-3"
                      // aria-label="Toggle bold"
                    >
                      {tutor?.name === "Jack" && (
                        <img
                          src="/man.png"
                          alt="Jack"
                          className="h-9 w-9 rounded-full"
                        />
                      )}
                      {tutor?.name === "Jill" && (
                        <img
                          src="/woman.png"
                          alt="Jack"
                          className="h-9 w-9 rounded-full"
                        />
                      )}
                      {!["Jack", "Jill"].includes(tutor?.name ?? "") && (
                        <Avatar>
                          <AvatarImage
                            src="https://github.com/shadcn.png"
                            alt="@shadcn"
                          />
                          <AvatarFallback>Tutor avatar</AvatarFallback>
                        </Avatar>
                      )}

                      <div className="flex flex-col items-start">
                        <p className=""> {tutor.name}</p>
                        <div className="flex  gap-1 text-xs text-gray-400">
                          <p>Neutral</p>
                          <p>|</p>
                          <button className="flex items-center justify-start gap-1 hover:underline">
                            Play voice <Play size={8} />
                          </button>
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                );
              })}
            </ToggleGroup>
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            disabled={createChatMutation.isLoading}
            className="px-10"
            size="lg"
            onClick={() => handleSubmit()}
          >
            {createChatMutation.isLoading ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Start chatting"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChatCreationModal;
