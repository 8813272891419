"use client";

import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";

const CrispChat = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      Crisp.configure("7f6ec54d-81bb-42ac-8be0-9e3baca8c50b");
    }
  });

  return null;
};

export default CrispChat;
