"use client";

import React from "react";
import { useState, useEffect } from "react";
import { api } from "~/trpc/react";
import { useSession } from "next-auth/react";
import { DialogTitle } from "~/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";

import { Button } from "~/components/ui/button";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { languages } from "~/constants/languages";

type LanguageChooseModalProps = {
  setDialogContent: (value: React.SetStateAction<string>) => void;
};

const FormSchema = z.object({
  primaryLanguageCode: z.string({
    required_error: "Please select a language.",
  }),
  learningLanguageCode: z.string({
    required_error: "Please select a language.",
  }),
});

const LanguageChooseModal: React.FC<LanguageChooseModalProps> = ({
  setDialogContent,
}) => {
  const utils = api.useUtils();
  const { data: session } = useSession();
  const updateSettingsMutation = api.settings.update.useMutation();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const userId = session?.user?.id;
    if (!userId) {
      return;
    }

    setDialogContent("createChat");
    try {
      updateSettingsMutation.mutate(
        {
          defaultTutorId: "",
          ...data,
        },
        {
          onSuccess: () => {
            void utils.settings.get.invalidate();
          },
          onError: () => {
            console.error("Error updating settings");
          },
        },
      );
    } catch (error) {
      // Handle errors - show error messages
      console.error("Error updating settings:", error);
    }
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8"
        >
          <DialogTitle className=" text-center text-3xl">
            Choose language
          </DialogTitle>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="primaryLanguageCode" /// what th is that
              render={({ field }) => (
                <FormItem className="flex min-h-[250px] flex-col items-center gap-4 rounded-lg border border-gray-200 bg-white p-8 shadow-md">
                  <FormLabel className="text-xl">My language</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select language" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {languages.map((item) => {
                        return (
                          <SelectItem
                            key={item.value}
                            value={item.value}
                            onSelect={() => {
                              form.setValue("primaryLanguageCode", item.value);
                            }}
                          >
                            <div className="flex">
                              <div className="mr-2">{item.icon}</div>
                              <div>{item.label}</div>
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormDescription className="text-center text-xs">
                    Your native language. This will be used for translating &
                    explaining words & grammar.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="learningLanguageCode" /// what th is that
              render={({ field }) => (
                <FormItem className="flex flex-col items-center gap-4 rounded-lg border border-gray-200 bg-white p-8 shadow-md">
                  <FormLabel className="text-xl">I am learning</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select language" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {languages.map((item) => {
                        return (
                          <SelectItem
                            key={item.value}
                            value={item.value}
                            onSelect={() => {
                              form.setValue("learningLanguageCode", item.value);
                            }}
                          >
                            <div className="flex">
                              <div className="mr-3">{item.icon}</div>
                              <div>{item.label}</div>
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormDescription className="text-center text-xs">
                    Primary language you are learning. This language will be
                    used as a default for chats & learning materials.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex justify-center">
            <Button type="submit" size="lg" className="px-10">
              Next
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default LanguageChooseModal;
