"use client";

import React from "react";
import { useState, useEffect } from "react";
import { api } from "~/trpc/react";
import { useSession } from "next-auth/react";
import { Dialog, DialogContent } from "~/components/ui/dialog";

import LanguageChooseModal from "./language-choose-modal";
import ChatCreationModal from "./chat-creation-modal";

const OnboardingModal = () => {
  const { data: session } = useSession();
  const utils = api.useUtils();
  const getSettingsQuery = api.settings.get.useQuery();
  const createSettingsMutation = api.settings.create.useMutation();

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("chooseLanguage");

  useEffect(() => {
    const userId = session?.user?.id;
    if (!userId) {
      return;
    }

    if (getSettingsQuery.isLoading) {
      return;
    }

    if (
      getSettingsQuery.data?.learningLanguageCode === "" &&
      getSettingsQuery.data?.primaryLanguageCode === ""
    ) {
      // Case 1: Both fields are empty strings, show the modal
      setIsOnboardingModalOpen(true);
    } else if (!getSettingsQuery.data) {
      // Case 2: If fields don't exist in the database, create them with default values
      createSettingsMutation.mutate(
        {
          userId: session?.user?.id,
          primaryLanguageCode: "",
          learningLanguageCode: "",
          defaultTutorId: "FFKOUTTPG",
        },
        {
          onSuccess: () => void utils.settings.get.invalidate(),
        },
      );
    } else {
      // Case 3: Any other case (f.i languages are already there, which means onboarding was done), don't show the modal
      setIsOnboardingModalOpen(false);
    }
  }, [getSettingsQuery.data, session?.user?.id, getSettingsQuery.isLoading]);

  // console.log("settings query", getSettingsQuery.data);
  // console.log("state of isOnboardingModal", isOnboardingModalOpen);

  return (
    <Dialog open={isOnboardingModalOpen}>
      <DialogContent className="p-12 sm:max-w-[725px]">
        {dialogContent === "chooseLanguage" && (
          <LanguageChooseModal setDialogContent={setDialogContent} />
        )}

        {dialogContent === "createChat" && (
          <ChatCreationModal
            setIsOnboardingModalOpen={setIsOnboardingModalOpen}
            setDialogContent={setDialogContent}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingModal;
